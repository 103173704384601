<template>
  <div class="sms">
    <div class="sms__content">
      <div class="sms__create">



        <div class="app-form sms__form">
          <div class="app-form__header">Синхронизация с sms-aero</div>
          <div class="app-form__content">
            <form @submit.prevent="saveSmsAeroDate" class="sms__create-api-connect-form">
              <div сlass="app-form__field-item">
                <div class="app-form__field-name">Api-ключ</div>
                <app-input
                    v-model="smsData.key"
                    :class-name="'sms__field'"
                    :placeholder="'api-ключ'"
                />
              </div>
              <div сlass="app-form__field-item">
                <div class="app-form__field-name">email</div>
                <app-input
                    v-model="smsData.email"
                    :class-name="'sms__field'"
                    :placeholder="'email'"
                />
              </div>
              <div сlass="app-form__field-item">
                <div class="app-form__field-name">sign</div>
                <app-input
                    v-model="smsData.sing"
                    :class-name="'sms__field'"
                    :placeholder="'sign'"
                />
              </div>
              <app-button @click="saveSmsAeroDate" type="button" value="Созранить" />
            </form>
          </div>
        </div>
        <div class="app-form sms__form">
          <div class="app-form__header">Протестировать соедениение с sms-aero</div>
          <div class="app-form__content">
            <form @submit.prevent="textConnect" class="sms__create-api-connect-form">
              <app-button @click="textConnect" type="button" value="Тестировать соединение" />
            </form>
          </div>
        </div>




      </div>
    </div>
    <div class="sms__description">

      <img :src="smsAeroLogo" alt="">

      <p>
        Мы провели интеграцию с сервисом sms-aero для того, чтобы Вы могли уведомлять свои пользователей о наличии товара через sms сообщения!
      </p>

      <h2>Как подключить отправку уведомлений через sms?</h2>

      <p></p>

      <h4>1. Зарегистрироваться на смс-aero</h4>

      <p>
        Перейдите в сервис <a href="https://smsaero.ru/">sms-aero</a>, заполнитель форму регистрации, подтвердите номера телефона.
      </p>

      <h4>2. Сохранить api-ключ</h4>

      <p>
        Войти те в аккаунт и перейдите в раздел <a href="https://smsaero.ru/cabinet/settings/apikey/">Настройки -> Api и SMTP</a>. На данной странице необходимо сгенерировать api-ключ
      </p>

      <img width="50%" :src="smsAeroKey" alt="">

      <p>
        Скопируйте сгенерированый ключ и вставьте в соответствующие поле не данной странице (страница настроект sms уведомлений приложения).
        Так же в форму сихрон
        Сохраните значение.
      </p>

      <h4>3. Протестировать соединение</h4>
      <h4>4. Включить отправку смс</h4>


    </div>

    <app-message className="sms__message" ref="app-message" />

  </div>
</template>

<script>
import AppButton from "../components/base-ui/app-button";
import { PnnApi } from "../api/pnn-api";
import smsAeroLogo from '../assets/smsaero_logo.png';
import smsAeroKey from '../assets/smsaero-api-key.png';
import AppInput from "../components/base-ui/app-input";

export default {
  name: 'sms',
  components: {AppInput, AppButton},
  data() {
    return {
      smsAeroLogo: smsAeroLogo,
      smsAeroKey: smsAeroKey,
      smsData: {
        key: '',
        email: '',
        sing: '',
      }
    }
  },
  methods: {
    saveSmsAeroDate() {
        return this.$store.dispatch('updateAccount', {
          sms_aero_key: this.smsData.key,
          sms_aero_email: this.smsData.email,
          sms_aero_sign: this.smsData.sing
        })
            .then(() => {
              this.$refs['app-message'].open({
                type: 'success',
                message: 'Сохранено',
              })
            })
            .catch(() => {
              this.$refs['app-message'].open({
                type: 'warning',
                message: 'Ошибка',
              })
            })
    },
    textConnect() {
      return PnnApi.smsAeroTestConnect()
          .then(response => {
            console.log('res', response)
            if (response.status && response.status === 'error') {
              throw new Error(response);
            }

            this.$refs['app-message'].open({
              type: 'success',
              message: 'Соедение корректное',
            })
          })
          .catch(() => {
            this.$refs['app-message'].open({
              type: 'warning',
              message: 'Ошибка соединения',
            })
          })
    }
  },
  computed: {
    sms_aero_key() {
      return this.$store.state.account.accountData.sms_aero_key || '';
    },
    sms_aero_email() {
      return this.$store.state.account.accountData.sms_aero_email || '';
    },
    sms_aero_sign() {
      return this.$store.state.account.accountData.sms_aero_sign || '';
    },
  },
  watch: {
    sms_aero_key() {
      this.smsData.key = this.sms_aero_key;
    },
    sms_aero_email() {
      this.smsData.email = this.sms_aero_email;
    },
    sms_aero_sign() {
      this.smsData.sing = this.sms_aero_sign;
    }
  },
  mounted() {
    this.smsData.key = this.sms_aero_key;
    this.smsData.email = this.sms_aero_email;
    this.smsData.sing = this.sms_aero_sign;
  }
}
</script>

<style lang="scss">
.sms {
  position: relative;
  display: flex;

  &__form {
    margin-bottom: 30px;
  }

  &__message {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  &__content {
    width: 580px;
    padding: 30px;
  }
  &__description {
    padding: 30px;
    flex-grow: 1;
  }

  &__field {
    margin-bottom: 10px;
  }
}
</style>